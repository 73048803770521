/* アンカーリンク */
export default class AnchorLink {
    constructor() {
        this.win = window;
        this.hash = decodeURI(location.hash).replace('#', '');
        this.posOffset = 100; //(this.win.innerWidth < 768) ? 100 : 0; // アンカー位置からのずれ幅
        if (this.hash != '' && !/\W/.test(this.hash)) {
            this.outerPage(); // ページ外からのアンカーリンク
        }
        else {
            this.triggers = document.querySelectorAll('.js-anchor');
            this.innerPage(); // ページ内アンカーリンク
        }
    }
    innerPage() {
        this.triggers.forEach((elm) => {
            const trigger = elm;
            const id = trigger.getAttribute('href').replace('#', '');
            const target = document.getElementById(id);
            if (target) {
                trigger.addEventListener('click', (ev) => {
                    ev.preventDefault();
                    window.scrollTo({
                        top: target.getBoundingClientRect().top + window.scrollY - this.posOffset,
                        behavior: 'smooth'
                    });
                });
            }
        });
    }
    outerPage() {
        const target = document.getElementById(this.hash);
        if (target) {
            setTimeout(() => {
                window.scrollTo({
                    top: target.getBoundingClientRect().top + window.scrollY - this.posOffset - 50,
                    behavior: 'smooth'
                });
            }, 1000);
        }
    }
}
