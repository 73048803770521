/// <reference path="../node_modules/@types/jquery/index.d.ts" />
/* 汎用開閉コンテナー */
export default class ToggleContainer {
    constructor(_wrappers, _triggerQuery, _activeClassName = 'active') {
        this.$wrappers = $(_wrappers); // 開閉パネルラッパー
        this.triggerQuery = _triggerQuery; // トグルボタン
        this.activeClassName = _activeClassName; // 開くときのクラス名
        this.destroy();
    }
    init() {
        this.$wrappers.each((idx, elm) => {
            const $wrapper = $(elm);
            const $toggleBtn = $(this.triggerQuery, $wrapper);
            $toggleBtn.on('click.toggleContainer', (ev) => {
                ev.preventDefault();
                $wrapper.toggleClass(this.activeClassName);
            });
        });
    }
    destroy() {
        this.$wrappers.each((idx, elm) => {
            const $wrapper = $(elm);
            const $toggleBtn = $(this.triggerQuery, $wrapper);
            $toggleBtn.off('.toggleContainer');
            // $wrapper.removeClass(this.activeClassName);
        });
    }
}
